import React, { useState, useEffect, useRef } from 'react';
import ShowKanali from './components/ShowKanali';
import './App.css';
import Navbar from './components/Navbar';
import logoEng from './images/pocetnaEng.png';
import logoExYu from './images/pocetnaBiH.png';
import MyButton from './components/MyButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegram, faInstagram, faDiscord, faViber, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import logoRdLive from './images/rdLive.png';
import { useTranslation } from './components/LanguageContext';

function App() {
  const { translate, currentLanguage } = useTranslation();
  const phoneNumber = '+38762897565';
  const viberPhoneNumberRef = useRef(null);
  const [viberCopied, setViberCopied] = useState(false);
  const waPhoneNumberRef = useRef(null);
  const [waCopied, setWaCopied] = useState(false);

  const copyViberPhoneNumber = () => {
    viberPhoneNumberRef.current.select();
    document.execCommand('copy');
    setViberCopied(true);

    setTimeout(() => {
      setViberCopied(false);
    }, 2000);
  };

  const copyWaPhoneNumber = () => {
    viberPhoneNumberRef.current.select();
    document.execCommand('copy');
    setWaCopied(true);

    setTimeout(() => {
      setWaCopied(false);
    }, 2000);
  };

  const scrollToSection = (sectionId) => {
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="app">
      <Navbar />
      <div id="home">
        <div className="homeContent">
          <div className='rdOnlineTvLogo'>
            <img src={currentLanguage === 'en' ? logoEng : logoExYu} />
          </div>
          <MyButton onClick={() => scrollToSection('#channels')} className="my-button">
            <h4 className='btnTxt'>{translate('channels')}</h4>
          </MyButton>
          <div className="logoBottomRight">
            <h3 className='poweredBy'>Powered by:</h3>
            <a href="https://rd-live.com/" target="_blank" rel="noopener noreferrer">
              <div className='logoRdLive'>
                <img src={logoRdLive} alt="Logo" />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div id="channels">
        <ShowKanali />
      </div>
      <div id="offers">
        <div className='offerBoxes'>
          <div className='offerBox'>
            <h3 className='months'>{translate('freeTrial')}</h3>
            <h1 className='offerBoxTxt'>0€</h1>
          </div>
          <div className='offerBox'>
            <h3 className='months'>{translate('oneMonth')}</h3>
            <h1 className='offerBoxTxt'>10€</h1>
          </div>
          <div className='offerBox'>
            <h3 className='months'>{translate('sixMonths')}</h3>
            <h1 className='offerBoxTxt'>50€</h1>
          </div>
          <div className='offerBox'>
            <h3 className='months'>{translate('twelveMonths')}</h3>
            <h1 className='offerBoxTxt'>90€</h1>
          </div>
        </div>
        <div className='order'>
          <div className='orderIPTV'>{translate('offerText')}</div>
          <div className='social'>
            <a href="https://www.instagram.com/rd.online.tv/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className='icon' icon={faInstagram} />
            </a>
            <a href="https://discordapp.com/users/486081779997081602" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className='icon' icon={faDiscord} />
            </a>
            <a href='https://t.me/rdonlinetv' target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className='icon' icon={faTelegram} />
            </a>
            <a href='https://api.whatsapp.com/send/?phone=385919160145&text&type=phone_number&app_absent=0' target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon className='icon' icon={faWhatsapp} />
            </a>
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;